export class Grid {
	constructor(minWidth, gridElement) {
		this.minWidth = minWidth;
		this.gridElement = gridElement;
		this.gridContainer = null;
		this.children = null;
		this.gridItems = null;
		this.isSafari = false;
	}

	init() {
		this.gridContainer = this.gridElement.querySelector('.grid__container');
		this.children = Array.from(this.gridElement.children);
		this.gridItems = Array.from(this.gridContainer.children);

		this.isSafari =
			navigator.vendor
			&& navigator.vendor.indexOf('Apple') > -1
			&& navigator.userAgent
			&& navigator.userAgent.indexOf('CriOS') === -1
			&& navigator.userAgent.indexOf('FxiOS') === -1
			|| this.isSafari;

		window.addEventListener('resize', () => this.update());

        	this.update();
	}

	update() {
		if (document.documentElement.clientWidth < this.minWidth) {
			this.gridContainer.style.height = '';

			if(this.isSafari) {
				this.gridItems.forEach(gridItem => {
					gridItem.style.height = '';
				});
			}
			return;
		}
		let maxGridHeight = document.documentElement.clientWidth / 2;
		maxGridHeight -= parseFloat(window.getComputedStyle(this.gridElement)['paddingTop']);
		maxGridHeight -= parseFloat(window.getComputedStyle(this.gridElement)['paddingBottom']);
		maxGridHeight -= parseFloat(window.getComputedStyle(this.gridContainer)['marginBottom']);
		this.children.forEach(child => {
			if (child !== this.gridContainer) {
				const childsStyles = window.getComputedStyle(child);
				maxGridHeight -= child.offsetHeight;
				maxGridHeight -= parseFloat(childsStyles['marginTop']);
				maxGridHeight -= parseFloat(childsStyles['marginBottom']);
			}
		});

		this.gridContainer.style.height = `${maxGridHeight}px`;

		//	fix for Safaris too height grid_items:
		if (!this.gridItems || !this.isSafari) {
			return
		}

		const rowCount = Math.ceil(this.gridItems.length / 3);
		const gapsInPx = (rowCount - 1) * 20;
		let maxGridItemHeight = (maxGridHeight - gapsInPx) / rowCount;
		maxGridItemHeight = Math.round(maxGridItemHeight * 100) / 100;

		this.gridItems.forEach(gridItem => {
			gridItem.style.height = `${maxGridItemHeight}px`;
		});
	}
}
