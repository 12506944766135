import Glide from "@glidejs/glide";

export class Slider {

	constructor(sliderElement) {
		this.sliderElement = sliderElement;
	}

	init() {
		this.slider = new Glide(this.sliderElement, {
			rewind: false,
			gap: 0
		}).mount();

		this.sliderElement.querySelector('.glide__arrow--left').addEventListener('click', () => {
			this.slider.go('<');
		});

		this.sliderElement.querySelector('.glide__arrow--right').addEventListener('click', () => {
			this.slider.go('>');
		});
	}
}
