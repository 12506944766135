import { App } from "../unicontrol";

export class TeaserList {

    constructor(isCollection, teaserListElement, minWidth) {
        this.isCollection = isCollection;
        this.teaserListElement = teaserListElement;
        this.minWidth = minWidth;
        this.teaserListItems = this.teaserListElement.querySelectorAll('.teaser-list__item');
	    this.alternativeSorting = this.teaserListElement.classList.contains('teaser-list--alternative-sorting');
    }

    init() {
        window.addEventListener('resize', () => this.update());

	    window.addEventListener('ready', () => this.update());

        this.update();
    }

    update() {
	    this.setHeight();
	    this.resort();
    }

    setHeight() {
    	if(this.isCollection) {
    		return;
	    }
        if (document.documentElement.clientWidth < this.minWidth) {
            // remove class teaser-list__item--hidden from all teaser items
            this.teaserListItems.forEach(teaserListItem =>
                teaserListItem.classList.remove('teaser-list__item--hidden'));
            return;
        }

        // otherwise it would be in full height at the beginning and the calculation would be wrong
	    this.teaserListElement.style.display = 'inline-table';
	    this.teaserListElement.style.position = 'absolute';

        // add class teaser-list__item--hidden to all teaser items
        this.teaserListItems.forEach(teaserListItem =>
            teaserListItem.classList.add('teaser-list__item--hidden'));

        for (let i = 0; i < this.teaserListItems.length; i++) {
            // remove the hidden class
            this.teaserListItems[i].classList.remove('teaser-list__item--hidden');

            // 1. get precise width and height of the teaser list element
            // 2. always get the previous integer value
            const width = Math.floor(parseFloat(getComputedStyle(this.teaserListElement).width));
            const height = Math.floor(parseFloat(getComputedStyle(this.teaserListElement).height));

            if (height > width) {
                // if we get to this line the teaser list exceeds its max. height defined by its width (square),
                // so we have to add the hidden class again
                this.teaserListItems[i].classList.add('teaser-list__item--hidden');

                // we break the loop at this point since following items already have the hidden class, so we don't
                // have to continue at this point to get better performance
                break;
            }
        }
	    this.teaserListElement.style.display = '';
        this.teaserListElement.style.position = '';
    }

    resort() {
    	if(!this.isCollection || !this.alternativeSorting) {
    		return;
	    }
	    if (document.documentElement.clientWidth < this.minWidth) {
		    this.teaserListItems.forEach(teaserListItem => {
			    teaserListItem.style.order = '';
			    teaserListItem.classList.remove('teaser-list__item--resort');
			    teaserListItem.classList.remove('teaser-list__item--left');
			    teaserListItem.classList.remove('teaser-list__item--right');
		    });
		    return;
	    }

	    const half = Math.ceil(this.teaserListItems.length / 2);

	    for (let i = 0; i < this.teaserListItems.length; i++) {
			    this.teaserListItems[i].classList.add('teaser-list__item--resort');
	    }
	    for (let i = 0; i < half; i++) {
			    this.teaserListItems[i].style.order = `${i * 2}`;
			    this.teaserListItems[i].classList.add('teaser-list__item--left');
	    }
	    for (let i = half; i < this.teaserListItems.length; i++) {
			    this.teaserListItems[i].style.order = `${(i - half) * 2 + 1}`;
			    this.teaserListItems[i].classList.add('teaser-list__item--right');
	    }
    }
}
