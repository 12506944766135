import { mapStyle } from "../stores/map-style-store";

export class MapAcceptDecline {
	constructor(mapAcceptDeclineElement, maps) {
		this.mapButtonAccept = mapAcceptDeclineElement.querySelector('.map__button--accept');
		this.mapButtonDecline = mapAcceptDeclineElement.querySelector('.map__button--decline');
		this.mapButtonOverlay = mapAcceptDeclineElement;
		this.maps = maps;
	}

	init() {
		this.mapButtonAccept.addEventListener('click', () => this.acceptDecline('accepted'));
		this.mapButtonDecline.addEventListener('click', () => this.acceptDecline('declined'));
		if (localStorage.getItem('show_map')) {
			this.mapButtonOverlay.remove();
		}
	}

	acceptDecline(acceptDecline) {
		localStorage.setItem('show_map', acceptDecline);
		this.mapButtonOverlay.remove();
		this.maps.forEach(map => map.init());
	}

}

export class Map {

	constructor(minWidth, mapElement) {
		this.minWidth = minWidth;
		this.mapElement = mapElement;
		this.parentSection = null;
		this.parentText = null;
		this.apiKey = false;
		this.centerPosition = {};
		this.zoom = 15;
		this.mapPinTitle = '';
	}

	init() {
		this.mapBody = this.mapElement.querySelector('.map__body');
		this.mapImage = this.mapElement.querySelector('.map__image');
		this.apiKey = this.mapBody.dataset.apikey ? this.mapBody.dataset.apikey : this.apiKey;
		if (!this.mapBody || !this.apiKey) {
			return;
		}
		this.parentSection = this.mapElement.parentElement.parentElement;
		this.parentText = this.mapElement.parentElement;
		this.centerPosition = {
			lat: parseFloat(this.mapBody.dataset.lat),
			lng: parseFloat(this.mapBody.dataset.lng)
		};
		this.zoom = parseInt(this.mapBody.dataset.zoom);
		this.mapPinTitle = this.mapBody.dataset.mappintitle ? this.mapBody.dataset.mappintitle : this.mapPinTitle;
		this.drawMap();
		window.addEventListener('load', () => this.updateSize());
		window.addEventListener('resize', () => this.updateSize());
		this.updateSize();
		return this;
	}

	updateSize() {
		if (document.documentElement.clientWidth < this.minWidth) {
			this.mapElement.style.height = '';
			return;
		}
		let mapMaxHeight = this.parentSection.offsetWidth;
		mapMaxHeight -= parseFloat(window.getComputedStyle(this.parentSection)['paddingTop']);
		const parentTextHeadline = this.parentSection.querySelector('.text__headline');
		if (parentTextHeadline) {
			mapMaxHeight -= parentTextHeadline.offsetHeight;
			mapMaxHeight -= parseFloat(window.getComputedStyle(parentTextHeadline)['paddingBottom']);
		}
		Array.from(this.parentText.children).forEach(child => {
			if (child !== this.mapElement) {
				const childsStyles = window.getComputedStyle(child);
				mapMaxHeight -= child.offsetHeight;
				mapMaxHeight -= parseFloat(childsStyles['marginTop']);
				mapMaxHeight -= parseFloat(childsStyles['marginBottom']);
			}
		});
		this.mapElement.style.height = `${mapMaxHeight - 5}px`;
	}

	drawMap() {
		if (!localStorage.getItem('show_map') || localStorage.getItem('show_map') !== 'accepted') {
			return;
		}
		this.mapBody.classList.remove('map__body--hidden');
		if (this.mapImage) {
			this.mapImage.classList.add('map__image--hidden');
		}
		const apiSrc = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey;
		this.loadScript(apiSrc, () => {
			this.initMap();
		});
	}

	loadScript(url, callback) {
		const head = document.head;
		let script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = url;
		script.onreadystatechange = callback;
		script.onload = callback;
		head.appendChild(script);
	}

	initMap() {
		const map = new google.maps.Map(this.mapBody, {
			center: this.centerPosition,
			zoom: this.zoom,
			styles: mapStyle,
			disableDefaultUI: true
		});

		if (!this.mapPinTitle) {
			return;
		}

		const image = {
			url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
			labelOrigin: new google.maps.Point(130, 12)
		};

		new google.maps.Marker({
			position: this.centerPosition,
			map: map,
			title: this.mapPinTitle,
			label: this.mapPinTitle,
			icon: image
		});
	}
}
