export class HeadlinesManager {

	constructor() {
		this.headlines = [];
	}

	init() {
		this.headlines = Array.from(
			document.querySelectorAll('h1')
		);
		this.headlines.shift();
		this.headlines.forEach(headline => {
			headline.outerHTML = headline.outerHTML
				.replace(/^<h1/g, '<h2')
				.replace(/h1>$/g, 'h2>');
		});
	}
}
