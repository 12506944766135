export class OrderManager {

    constructor(minWidth, skipForBodyClasses) {
        this.minWidth = minWidth;
        this.skipForBodyClasses = skipForBodyClasses;
        this.sections = [];
    }

    init() {
        if (document.body.classList.contains(this.skipForBodyClasses)) {
            return;
        }

        // check if the current page is the landing page
        this.isLanding = !!document.querySelector('.landing');

        // react to window resizes
        window.addEventListener('resize', () => this.update());

        // this will be called only once to initialize the background image settings
        this.update();
    }

    update() {
        // get all sections
        this.sections = Array.from(document.querySelectorAll(
            'section.editorial, section.teaser-list, section.grid, section.text, section.quotes, section.persons'
        ));

        if (document.documentElement.clientWidth < this.minWidth) {
            this.sections.forEach(section => section.style.order = null);
            return;
        }

        if (this.isLanding) {
            const firstElement = this.sections.shift();
            firstElement.style.order = '-1';
        }

        // create an array containing the order values
        const orders = this.generateOrdersForSections();

        // apply the order style
        for (let i = 0; i < this.sections.length; i++) {
            this.sections[i].style.order = `${orders[i]}`;
        }
    }

    generateOrdersForSections(sections) {
        // create an array containing the numbers from 0 to the count of sections
        const orders = Array.from(Array(this.sections.length).keys());

        // split the orders into chunks
        const chunkedOrders = this.chunk(orders, 2);

        let reverseElements = false;
        let wasReversed = false;

        chunkedOrders.forEach((item, index) => {
            if (this.isLanding) {
                // reverse every even chunk on landing page
                if (index % 2 !== 0) {
                    item = item.reverse();
                }
                return;
            }

            // make sure sub pages start with an editorial
            const firstSectionIndex = index * 2;
            const firstSectionIsEditorial = this.sections[firstSectionIndex].classList.contains('editorial');

            reverseElements = firstSectionIsEditorial && wasReversed;

            if (reverseElements) {
                item = item.reverse();
            }

            wasReversed = !wasReversed;
        });

        return chunkedOrders.flat();
    }

    chunk(array, chunkSize) {
        const chunked = [];

        for (let i = 0, len = array.length; i < len; i += chunkSize) {
            chunked.push(array.slice(i, i + chunkSize));
        }

        return chunked;
    }
}
