import {Navigation} from './components/navigation';
import {Counter} from './components/counter';
import {Slider} from './components/slider';
import { TeaserList } from './components/teaser-list';
import {MapAcceptDecline, Map} from './components/map';
import {BackgroundImageManager} from './stores/background-image-manager';
import {OrderManager} from './stores/order-manager';
import {HeadlinesManager} from './stores/headlines-manager';
import { Grid } from './components/grid';
import {Quote} from './components/quote';

export class App {

    init() {
    	const breakpoints = {
		    mobile: 0,
		    tablet: 500,
		    desktopSmall: 1024,
		    desktopLarge: 1280,
	    };

        setTimeout(() => document.body.classList.remove('preload'), 300);

        new Navigation().init();

        const counterElements = document.querySelectorAll('.counter');
        Array.from(counterElements).forEach(counterElement => {
            new Counter(counterElement).init();
        });

        const sliderElements = document.querySelectorAll('.glide');
        Array.from(sliderElements).forEach(sliderElement => {
	        new Slider(sliderElement).init();
        });

        const teaserElements = document.querySelectorAll('.teaser-list');
        const isCollection = document.querySelector('body').classList.contains('collection');
        Array.from(teaserElements).forEach(teaserElement => {
	        new TeaserList(isCollection, teaserElement, breakpoints.desktopLarge).init();
        });

	    let maps = [];
	    const mapElements = document.querySelectorAll('.map');
	    Array.from(mapElements).forEach(mapElement => {
		    const map = new Map(breakpoints.desktopLarge, mapElement).init();
		    maps.push(map);
	    });

	    const mapAcceptDeclineElements = document.querySelectorAll('.map__accept-decline');
	    Array.from(mapAcceptDeclineElements).forEach(mapAcceptDeclineElement => {
		    new MapAcceptDecline(mapAcceptDeclineElement, maps).init();
	    });

        new BackgroundImageManager({
            minBreakpoints: breakpoints
        }).init();

        new OrderManager(breakpoints.desktopLarge, 'collection').init();

        new HeadlinesManager().init();

	    const gridElements = document.querySelectorAll('.grid');
	    Array.from(gridElements).forEach(gridElement => {
		    new Grid(breakpoints.desktopLarge, gridElement).init();
	    });

	    const quotesElements = document.querySelectorAll('.quotes');
	    Array.from(quotesElements).forEach(quotesElement => {
		    new Quote(quotesElement, breakpoints.desktopLarge).init();
	    });
    }
}

window.addEventListener('load', () => new App().init());
