import '../../images/editorial-squares/blue-squares-mobile.svg';
import '../../images/editorial-squares/blue-squares-tablet.svg';
import '../../images/editorial-squares/blue-squares-desktop.svg';
import '../../images/editorial-squares/magenta-squares-mobile.svg';
import '../../images/editorial-squares/magenta-squares-tablet.svg';
import '../../images/editorial-squares/magenta-squares-desktop.svg';
import '../../images/editorial-squares/magenta-squares.svg';

export class BackgroundImageManager {

    constructor(options) {
        this.options = options;
        this.backgroundImages = {};
        this.isCollection = true;
    }

    init() {
        // determine if we are on a collection page
        this.isCollection = document.body.classList.contains('collection');

        // react to window resizes
        window.addEventListener('resize', () => this.update());

        // this will be called only once to initialize the background image settings
        this.update();
    }

    update() {
        // determine the current device format
        this.currentFormat = this.findCurrentFormat();

        // use the previous selected elements or query all background source elements
        this.elements = this.elements || document.querySelectorAll('[data-background-manager]');

        // iterate all found elements
        this.elements.forEach(element => {
            // create uniformed key names
            const key = element.className.replace(' ', '.');

            if (!this.backgroundImages[key]) {
                this.backgroundImages[key] = {
                    showSquares: !!element.dataset.showSquares,
                };

                if (element.dataset.backgroundSources) {
                    this.backgroundImages[key]['backgroundSources'] = JSON.parse(element.dataset.backgroundSources);
                }

                // remove all the data attributes from HTML because we store them in memory
                delete element.dataset.backgroundSources;
                delete element.dataset.showSquares;
            }

            const backgroundValues = this.getBackgroundValuesByKey(key);

            // apply the styles to the element
            element.style.background = backgroundValues.background.join(', ');
            element.style.backgroundPosition = backgroundValues.position.join(', ');
            element.style.backgroundSize = backgroundValues.size.join(', ');
            element.style.backgroundRepeat = backgroundValues.repeat.join(', ');
        });
    }

    findCurrentFormat() {
        const minBreakpoints = this.options.minBreakpoints;
        const viewportWidth = document.documentElement.clientWidth;

        // descending sort the keys depending in the given values and reverse the order
        const sortedKeys = Object.keys(minBreakpoints)
            .sort((a, b) => minBreakpoints[a] - minBreakpoints[b])
            .reverse();

        // find the current format depending on the found array
        return sortedKeys.find((key) => minBreakpoints[key] < viewportWidth);
    }

    getFormatForSquareImages() {
        return this.currentFormat === 'desktopLarge' || this.currentFormat === 'desktopSmall'
            ? 'desktop'
            : this.currentFormat;
    }

    getBackgroundValuesByKey(key) {
        const hasBackgroundSources = this.backgroundImages[key].backgroundSources;
        const hasMagentaSquares = this.backgroundImages[key].showSquares;
        const squareFormat = this.getFormatForSquareImages();

        const background = [];
        const position = [];
        const size = [];
        const repeat = ['no-repeat'];

        // add the magenta colored squares if the necessary option was selected and
        // if we are not on a collection template
        if (hasMagentaSquares && !this.isCollection) {
            background.push(`url(/user/themes/unicontrol/assets/images/magenta-squares-${squareFormat}.svg)`);
            position.push('right bottom');
            size.push('contain');
        }

        if (hasBackgroundSources) {
            // define the css value for the backdrop
            const backdrop = this.isCollection && this.currentFormat === 'desktopLarge'
                ? 'linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.1) 33%, rgba(0, 0, 0, 0.1) 100%)'
                : 'linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.1) 100%)';

            // add the backdrop
            background.push(backdrop);
            position.push('top left');
            size.push('contain');

            // add the background image
            background.push(`url(${this.backgroundImages[key].backgroundSources[this.currentFormat]})`);
            position.push('center');
            size.push('cover');
        } else {
            // add the blue squares
            background.push(`url(/user/themes/unicontrol/assets/images/blue-squares-${squareFormat}.svg)`);
            position.push('right bottom');
            size.push('contain');

            // add the cyan colored gradient
            background.push("radial-gradient(100% 267.39% at 0% 0%, #005870 0%, #00b4d5 100%)");
            position.push('center');
            size.push('contain');
        }

        return {
            background,
            position,
            repeat,
            size,
        };
    }
}
