export class Navigation {

    constructor() {
        this.routesNavList = document.querySelector('.nav-list--routes');
        this.socialNavList = document.querySelector('.nav-list--social');
        this.navToggle = document.querySelector('.nav__toggle');
        this.navCheckboxes = Array.from(
            this.routesNavList.querySelectorAll('.nav-item__toggle')
        );
        this.subNavLinks = Array.from(
            this.routesNavList.querySelectorAll('.nav-list .nav-list .nav-list__link')
        );
        this.header = document.querySelector('.header');
        this.subNavs = Array.from(
            this.routesNavList.querySelectorAll('.nav-list .nav-list')
        );
    }

    init() {
        this.navToggle.addEventListener('change', event => this.onNavToggleChangeHandler(event));

        this.navCheckboxes.forEach(checkbox =>
            checkbox.addEventListener('change', event => this.onSubNavToggleChangeHandler(event))
        );

        this.subNavLinks.forEach(link =>
            link.addEventListener('click', event => this.onSubNavLinkClickHandler(event))
        );

        window.addEventListener('resize', () => this.positionSubNavigations());

        this.positionSubNavigations();
    }

    onNavToggleChangeHandler(event) {
        if (event.target.checked) {
            return;
        }

        this.uncheckAllCheckboxes(event);
    }

    onSubNavToggleChangeHandler(event) {
        const rightStyle = event.target.checked
            ? event.target.nextElementSibling.offsetWidth + 18
            : 0;

        this.routesNavList.style.transform = `translate(-${rightStyle}px, 0px)`;
        this.socialNavList.style.transform = `translate(-${rightStyle}px, 0px)`;
    }

    onSubNavLinkClickHandler(event) {
        const hash = event.target.hash.substring(1);

        if (document.querySelector(`#${hash}`)) {
            this.uncheckAllCheckboxes(event);
        }
    }

    uncheckAllCheckboxes(event) {
        this.navCheckboxes.forEach(checkbox => {
            checkbox.checked = false;
        });

        const openedNav = event.target.parentElement.parentElement;

        openedNav.style.display = 'none';
        setTimeout(() => {
            openedNav.style.display = '';
        }, 100);

        this.navToggle.checked = false;
        this.routesNavList.style.transform = 'translate(0px, 0px)';
        this.socialNavList.style.transform = 'translate(0px, 0px)';
    }

    positionSubNavigations() {
        if (!this.header || !this.subNavs) {
            return;
        }
        this.subNavs.forEach(subNav => {
            const parentHeight = subNav.parentElement.offsetHeight;
            const linkPadding = parseInt(window.getComputedStyle(subNav.parentElement.querySelector('.nav-list__link'), null).getPropertyValue('padding-bottom'));
            subNav.style.top = `${parentHeight + linkPadding + 2}px`;
        })
    }
}
