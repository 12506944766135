export class Quote {
	constructor(quotesElement, minWidth) {
		this.quotesElement = quotesElement;
		this.quotes = null;
		this.children = null;
		this.minWidth = minWidth;
	}

	init() {
		this.children = Array.from(this.quotesElement.children);
		this.quotes = Array.from(this.quotesElement.querySelectorAll('.quote'));

		window.addEventListener('resize', () => this.update());

		// DOMContentLoaded is to early
		window.addEventListener('load', () => this.update());

		// And if the page is faster than the execution of this script
		this.update();
	}

	update() {
		if (document.documentElement.clientWidth < this.minWidth) {
			return;
		}

		// half display-width minus sections padding:
		let maxHeight = document.documentElement.clientWidth / 2;
		maxHeight -= parseFloat(window.getComputedStyle(this.quotesElement)['paddingTop']);
		maxHeight -= parseFloat(window.getComputedStyle(this.quotesElement)['paddingBottom']);

		// subtract every element that is not a quote__glide
		this.children.forEach(child => {
			if (!child.classList.contains('quotes__glide')) {
				const childsStyles = window.getComputedStyle(child);
				let childHeight = 0;
				childHeight += child.offsetHeight;
				childHeight += parseFloat(childsStyles['marginTop']);
				childHeight += parseFloat(childsStyles['marginBottom']);
				// substract this twice to center the element
				maxHeight -= childHeight * 2;
			}
		});

		// -40 to give a bit of padding
		maxHeight -= 40;

		this.quotes.forEach(quote => {
			// maximum font-size is 20px
			let fontSize = 20;
			quote.style.fontSize = `${fontSize}px`;

			// now decrease the fontsize till it fits smoothly
			while (quote.offsetHeight > maxHeight) {
				fontSize -= 0.25;
				quote.style.fontSize = `${fontSize}px`;
			}
		});
	}
}
