export class Counter {

    constructor(element, duration = 1250) {
        this.element = element;
        this.duration = duration;
        this.valueElement = this.element.querySelector('.counter__value');

        const matches = this.valueElement.textContent
            .match(/(\D*)?((\d+|,)+)(\D*)?/);

        this.prepend = matches && matches[1] ? matches[1] : '';
        this.end = matches && matches[2] ? matches[2] : '';
        this.append = matches && matches[4] ? matches[4] : '';

    }

    init() {
    	if(this.end === '') {
    		return;
	    }
        const stepTime = Math.floor(this.duration / this.end);
        const startTime = new Date().getTime();
        const endTime = startTime + this.duration;

        let timer = setInterval(() => {
            const now = new Date().getTime();
            const remaining = Math.max((endTime - now) / this.duration, 0);
            let value = parseFloat(this.end.replace(',', '.')) - (remaining * parseFloat(this.end.replace(',', '.')));
            if(this.end.indexOf(',') > -1) {
            	value = Number(value.toFixed(2));
            	value = value.toString().replace('.', ',');
            } else {
            	value = Math.round(value)
            }

            this.valueElement.innerHTML = `${this.prepend}${value}${this.append}`;

            if (value === this.end || remaining <= 0) {
            this.valueElement.innerHTML = `${this.prepend}${this.end}${this.append}`;
                clearInterval(timer);
            }
        }, stepTime);
    }
}
